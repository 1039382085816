import * as echarts from "echarts";

const install = function(Vue) {
  Object.defineProperties(Vue.prototype, {
    $echarts: {
      get() {
        return {
          initCharts: function(chartsInstance, chartsRef, chartsOptions) {
            const renderedInstance = echarts.init(chartsRef);
            if (renderedInstance) {
              chartsInstance = renderedInstance;
            } else {
              chartsInstance = echarts.init(chartsRef);
            }
            chartsInstance.setOption(chartsOptions);
            return chartsInstance;
          }
        };
      }
    }
  });
};

export default {
  install
};
