import http from "../../utils/http";
export default {
	// 报告定制
	reportCustom(params) {
		return http.post(`/mds/api/demand/reportCustom`, params);
	},
	// 咨询定制
	consultingCustom(params) {
		return http.post(`/mds/api/demand/consultingCustom`, params);
	},
	// 报告定制类型
	reportCustomType(params) {
		return http.get(`/mds/api/demand/reportCustomType`, params);
	},
	// 咨询/定制订单支付
	customizedPay(params) {
		return http.post(`/mds/interceptApi/consumerOrder/customized/pay`, params);
	},
};
