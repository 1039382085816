import http from "../../utils/http";
let url = "/mds/api/home/";
export default {
	// 数据埋点
	buriedPoint(params) {
		return http.get(`${url}buriedPoint`, params);
	},
	// 获取通知列表
	getNoticeList() {
		return http.get(`${url}getNoticeList`);
	},
	// 获取轮播图
	getBannerList(params) {
		return http.get(`${url}getBannerList`, params);
	},
	// 获取研究报告
	getReportList(params) {
		return http.get(`${url}getReportList`, params);
	},
	// 热门搜索
	searchKeyWord(params) {
		return http.get(`${url}searchKeyWord`, params);
	},
	// 搜索历史
	searchHistory(params) {
		return http.get(`${url}searchHistory`, params);
	},
	// 删除历史
	deleteHistory(params) {
		return http.delete(`${url}deleteHistory/${params}`);
	},
	// 搜索
	search(params) {
		return http.get(`${url}search`, params);
	},
	// 留言
	leaveMessage(params) {
		return http.post(`/mds/interceptApi/demand/leaveMessage`, params);
	},
	// 查询弹窗广告
	getPopupAdvertising(params) {
		return http.get(`${url}getPopupAdvertising`, params);
	},
	// 查询侧边栏广告
	getFloatingAdvertising(params) {
		return http.get(`${url}getFloatingAdvertising`, params);
	},
	// 获取系统配置
	getIndexSetting(params) {
		return http.get(`/mds/api/home/getIndexSetting`, params);
	},
	// 客服二维码
	getDescription(params) {
		return http.get(`/mds/api/description/get`, params);
	},
	// 获取留言分类
	getMessageTypeList(params) {
		return http.get(`/mds/interceptApi/demand/getMessageTypeList`, params);
	},
	//分页查询常见问题列表
	getQuestionList(params) {
		return http.get(`/mds/api/question/page`, params);
	},
	//查询常见问题
	getQuestionDetail(params) {
		return http.get(`/mds/api/question/get`, params);
	},
};
