import axios from "axios";
import { session } from "./sessions";
import { Message } from "element-ui";
import { doEncrypt,doDecryptStr } from "./smCrypto";
import settings from "../../settings";
import { UrlUtil } from "./UrlUtil";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "X-Requested-With": "XMLHttpRequest"
  }
});
//请求拦截
instance.interceptors.request.use(
  config => {
    config.headers["Authorization"] = session.getLoginToken();

    // 参数加密
    if (config.url == "/mds/interceptApi/bid/getProjectInfoDefalut" || config.url == "/mds/interceptApi/bid/getStatisticsByArea") {
		if (settings.requestEncrypt) {
			config.headers["encrypt"] = true;
			if (config.data) config.data = {data: doEncrypt(config.data)};
		}

		// Get请求映射Params参数
		if (config.method === "get" && config.params) {
			const params = UrlUtil.tansParams(config.params).slice(0, -1);
			const url = config.url + "?" + (settings.requestEncrypt ? `data=${doEncrypt(params)}` : params);
			config.params = {};
			config.url = url;
		}
	}




    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 请求返回后拦截
instance.interceptors.response.use(
  response => {
    const isDecrypt = response.headers.encrypt && response.headers.encrypt.includes("true");
    response.data = isDecrypt ? doDecryptStr(response.data) : response.data;
    const { code } = response.data;
    if (code !== 200) {
      if (Number(code) === 401) {
        Message.error("登录状态已过期");
        localStorage.clear(); // 用户失效清除所有缓存
        location.href = "#/login";
      }
        return Promise.reject(response);
    }
    return response;
  },
  error => {
    console.log("catch", error);
    return Promise.reject(error);
  }
);

export default instance;
