import { downloads } from "../../utils/download";
import http from "../../utils/http";
let url = "/mds/api/report/";
export default {
	// 研报列表-查询条件获取（省-年份）
	getReportSearch(params) {
		return http.get(`${url}getReportSearch`, params);
	},
	// 研报列表-查询条件获取（市）
	getReportSearchCity(params) {
		return http.get(`${url}getReportSearchCity`, params);
	},
	// 研报列表-查询条件获取（行业）
	getReportCategory(params) {
		return http.get(`${url}getReportCategory`, params);
	},
	// 研报列表-查询条件获取（行业）
	getNewReportCategory(params) {
		return http.get(`${url}getCategoryId`, params);
	},
	// 研报列表
	getReportPage(params) {
		return http.get(`${url}getReportPage`, params);
	},
	// 判断是否有阅读-下载权限及会员
	getReadAndDownload(params) {
		return http.get(`/mds/interceptApi/report/getReadAndDownload`, params);
	},
	// 判断是否有阅读-下载权限及会员
	getIndustryReadAndDownload(params) {
		return http.get(`/mds/interceptApi/industry/getReadAndDownload`, params);
	},
	// 研报详情（预览）
	getReportInfo(params) {
		return http.get(`/mds/interceptApi/report/getReportInfo`, params);
	},
	// 研报详情
	getReportPdf(params) {
		// return downloads(`/mds/interceptApi/report/getReportPdf`, params);
		return downloads(`/mds/interceptApi/report/getReportPdf`, params);
	},
	// 新研报详情
	getReportInfoApp(params) {
		return http.get(`/mds/interceptApi/report/getReportInfoApp`, params);
	},
	// 研报推荐阅读
	getReportRecommended(params) {
		return http.get(`${url}getReportRecommended`, params);
	},
	// 购买研报
	payReport(params) {
		return http.post(`/mds/interceptApi/report/pay`, params);
	},
	// 市场简报时间
	getPublishDate(params) {
		return http.get(`/mds/api/report/getPublishDate`, params);
	},
	// 购买研报明细
	getPayDetail(params) {
		return http.post(`/mds/interceptApi/report/payDetail`, params);
	},
	// 研报订单支付-new
	reportPay(params) {
		return http.post(`/mds/interceptApi/consumerOrder/report/pay`, params);
	},
};
