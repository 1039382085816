import request from '@/utils/axios'

/**
 * 获取到webSocket的前缀 （ws://localhost）
 * @return {string}
 */
export function getWebSocketPrefix () {
  // // 获取网站域名 +  端口号
  // let domain = document.location.protocol + '//' + document.location.host
  // // 判断api_base_url 是否设置
  // const VUE_APP_BASE_API = process.env.VUE_APP_BASE_API;
  // if (VUE_APP_BASE_API && VUE_APP_BASE_API !== '/') {
  //   if (VUE_APP_BASE_API.indexOf('https://') > -1 || VUE_APP_BASE_API.indexOf('http://') > -1) {
  //     domain = process.env.VUE_APP_BASE_API
  //   } else {
  //     domain += process.env.VUE_APP_BASE_API
  //   }
  // }
  const nodeEnv = process.env.VUE_APP_ENV
  let domain = "https://mds.ctcemti.com/api";
  if (nodeEnv === 'staging') {
    domain = 'http://stage-ctcemtiboot.mticloud.ctcemti.com/boot-api';
  }else if (nodeEnv === "development") {
			domain = "https://dev-mds.ctcemti.com/dev-api";
		}
  if (domain.startsWith('https:')) {
    return 'wss://' + domain.replace('https://', '')
  } else {
    return 'ws://' + domain.replace('http://', '')
  }
}

/**
 * 获取到支付单的websocket地址
 * @param payOrderId
 * @return {string}
 */
export function getPayOrderWebSocketUrl (payOrderId) {
  return getWebSocketPrefix() + "/ctcemti-cloud-pay/mds/open/pay/ws/payOrder/" + payOrderId + "/" + new Date().getTime();
}

/**
 * 查询应用下的支付方式
 * @param merchantAppId 支付应用
 * @return {*}
 */
export function listPayWay (merchantAppId) {
  return request.request({
    url: '/pay/payTool/payways/' + merchantAppId,
    method: 'get'
  })
}

/**
 * 测试支付下单
 * @param data 支付订单数据
 * @return {*}
 */
export function testPayOrder (data) {
  return request.request({
    url: '/pay/payTool/payOrder',
    method: 'post',
    data: data
  })
}

/**
 * 查询订单
 * @param data 支付订单数据
 * @return {*}
 */
export function payRetrieve (data) {
  return request.request({
    url: '/pay/payTool/payRetrieve',
    method: 'post',
    data: data
  })
}

/**
 * 查询渠道订单
 * @param payOrderId 订单ID
 * @return {*}
 */
export function payRetrieveChannel (payOrderId) {
  return request.request({
    url: '/pay/payTool/payRetrieveChannel',
    method: 'get',
    params: {payOrderId: payOrderId}
  })
}

/**
 * 查询渠道订单
 * @param payOrderId 订单ID
 * @return {*}
 */
export function payReissueChannel (payOrderId) {
  return request.request({
    url: '/pay/payTool/payReissueChannel',
    method: 'get',
    params: {payOrderId: payOrderId}
  })
}
/**
 * 上报支付结果
 * @return {*}
 */
export function returnUrlNotifyAPP (params) {
  return request.request({
    url: '/ctcemti-cloud-pay/mds/payNotify/returnUrlNotify/APP',
    method: 'get',
    params
  })
}

