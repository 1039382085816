import {download, downloads} from "../../utils/download";
import http from "../../utils/http";
export default {
	// 获取字典
	getDict(dictType) {
		return http.get(`/system/dict/data/type/${dictType}`);
	},
	// 查询行政区域列表
	regionList(params) {
		return http.get(`/system/region/list`, params);
	},
};
