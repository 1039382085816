import { downloads } from "../../utils/download";
import http from "../../utils/http";
export default {
	// 新增订阅
	addSubscribe(params) {
		return http.post(`/mds/interceptApi/subscribe`, params);
	},
	//查找订阅推送配置列表
	findUserSubscribeReminder(params) {
		return http.post(`/mds/api/findUserSubscribeReminder`, params);
	},
	//注册用户领取订阅
	receiveSubscribe(params) {
		return http.post(`/mds/interceptApi/receiveSubscribe`, params);
	},
	//发现用户订阅提醒
	interceptFindUserSubscribeReminder(params) {
		return http.post(`/mds/interceptApi/findUserSubscribeReminder`, params);
	},
	//获取已关注客户
	consumerClientNameList(params) {
		return http.get(`/mds/interceptApi/consumerClient/nameList`, params);
	},
	// 分页查询订阅推送列表
	subscribePage(params) {
		return http.get(`/mds/interceptApi/subscribe/page`, params);
	},
	// 查询订阅推送详情
	subscribeDetail(params) {
		return http.get(`/mds/interceptApi/subscribe/get`, params);
	},
	// 删除查询订阅推送列表
	subscribeAdd(params) {
		return http.post(`/mds/interceptApi/subscribe`, params, false);
	},
	// 删除查询订阅推送列表
	subscribeDelete(subscribeId) {
		return http.delete(`/mds/interceptApi/subscribe?subscribeId=${subscribeId}`);
	},
	// 更新查询订阅推送列表
	subscribeUpdate(params) {
		return http.put(`/mds/interceptApi/subscribe`, params);
	},

	//注册用户领取订阅
	addPushUidCid(params) {
		return http.post(`/mds/interceptApi/addPushUidCid`, params);
	},
	//开工时间查询区间
	getLandTimeSelector(params) {
		return http.get(`/mds/api/landTransaction/timeSelector`, params);
	},
	//招标信息-项目类型查询
	getBidProjectType(params) {
		return http.get(`/mds/api/bid/getBidProjectType`, params);
	},
};
