const moduleFiles = require.context("./modules", true, /\.js$/);

const modules = moduleFiles.keys().reduce((arr, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = moduleFiles(modulePath);
  arr[moduleName] = value.default;
  return arr;
}, {});

export default modules;
