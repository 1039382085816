import {download,downloads} from "../../utils/download";
import http from "../../utils/http";
export default {
	// 建筑行业中标大数据分析（地图数据）
	getDataAnalysis() {
		return http.get(`/mds/api/bid/getDataAnalysis`);
	},
	// 数据概览-柱状图-折线图
	getDataOverviewChart() {
		return http.get(`/mds/api/bid/getDataOverviewChart`);
	},
	// 数据概览
	getDataOverview() {
		return http.get(`/mds/api/bid/getDataOverview`);
	},
	// 项目名称联想搜索
	getProjectName(params) {
		return http.get(`/mds/interceptApi/bid/getProjectName`, params);
	},
	// 招标人联想搜索
	getTenderee(params) {
		return http.get(`/mds/interceptApi/bid/getTenderee`, params);
	},
	// 招标人联想分页
	pageTenderee(params) {
		return http.get(`/mds/interceptApi/bid/pageTenderee`, params);
	},
	// 中标人联想搜索
	getBidWinner(params) {
		return http.get(`/mds/interceptApi/bid/getBidWinner`, params);
	},
	// 中标人联想搜索分页
	pageBidWinner(params) {
		return http.get(`/mds/interceptApi/bid/pageBidWinner`, params);
	},
	// 政策规划库区域
	getAreaListForPolicyPlan(params) {
		return http.get(`/mds/api/industry/getAreaListForPolicyPlan`, params);
	},
	// 省市区获取
	getAreaList(params) {
		return http.get(`/mds/api/bid/getAreaList`, params);
	},
	// 省获取
	getProvinceList() {
		return http.get(`/mds/api/bid/getProvinceList`);
	},
	// 市区获取
	getCityList(params) {
		return http.get(`/mds/api/bid/getCityList`, params);
	},
	// 项目信息列表
	getProjectInfoPage(params) {
		return http.get(`/mds/api/bid/getProjectInfoPage`, params);
	},
	// 项目信息列表
	findPage(params) {
		return http.get(`/mds/api/winningBid/findPage`, params);
	},
	// 项目类型获取
	getBidProjectType(params) {
		return http.get(`/mds/api/bid/getBidProjectType`, params);
	},
	// 项目类型获取
	getBidProjectTypeAll(params) {
		return http.get(`/mds/api/bid/getBidProjectTypeAll`, params);
	},
	// 项目信息查询-默认数据
	getProjectLink(params) {
		return http.get(`/mds/interceptApi/bid/getProjectLink/${params}`);
	},
	// 项目信息查询-默认数据
	getProjectInfoDefalut(params) {
		return http.get(`/mds/interceptApi/bid/getProjectInfoDefalut`, params);
	},
	// 项目信息查询
	getProjectInfo(params) {
		return http.post(`/mds/interceptApi/bid/getProjectInfo`, params);
	},
	// 项目信息查询(不限次数)
	getProjectInfoDetail(params) {
		return http.get(`/mds/interceptApi/bid/getProjectInfoDetail`, params);
	},
	// 导出项目信息查询
	exportProjectInfo(params) {
		return downloads(`/mds/interceptApi/bid/exportProjectInfo`, params, "post");
	},
	// 统计数据查询-按地区
	getStatisticsByArea(params) {
		return http.post(`/mds/interceptApi/bid/getStatisticsByArea`, params);
	},
	// 导出统计数据-按地区
	exportStatisticsByArea(params) {
		return downloads(`/mds/interceptApi/bid/exportStatisticsByArea`, params, "post");
	},
	// 按招标人表格-导出
	exportStaTendereeByWinner(params) {
		return downloads(`/mds/interceptApi/bid/exportStaTendereeByWinner`, params, "post");
	},
	// 按中标人表格-导出
	exportStaBidWinnerByTenderee(params) {
		return downloads(`/mds/interceptApi/bid/exportStaBidWinnerByTenderee`, params, "post");
	},
	// 统计数据查询-思维导图导出
	exportStaTenderee(params) {
		return http.get(`/mds/interceptApi/bid/exportStaTenderee`, params);
	},
	// 统计数据查询-按招标人
	getStaTenderee(params) {
		return http.get(`/mds/interceptApi/bid/getStaTenderee`, params);
	},
	// 统计数据查询-按招标人-切换
	getStaTendereeSwitch(params) {
		return http.get(`/mds/interceptApi/bid/getStaTendereeSwitch`, params);
	},
	// 统计数据查询-按招标人-地区-市
	getStaTendereeByAreaCity(params) {
		return http.get(`/mds/interceptApi/bid/getStaTendereeByAreaCity`, params);
	},
	// 统计数据查询-按招标人-地区-区
	getStaTendereeByAreaProjectCounty(params) {
		return http.get(`/mds/interceptApi/bid/getStaTendereeByAreaProjectCounty`, params);
	},

	// 统计数据查询-按招标人-中标单位-分页
	getStaTendereeByWinnerPage(params) {
		return http.get(`/mds/interceptApi/bid/getStaTendereeByWinnerPage`, params);
	},
	// 统计数据查询-按招标人-地区-分页
	getStaTendereeByAreaPage(params) {
		return http.get(`/mds/interceptApi/bid/getStaTendereeByAreaPage`, params);
	},
	// 统计数据查询-按招标人-项目类型-分页
	getStaTendereeByProTypePage(params) {
		return http.get(`/mds/interceptApi/bid/getStaTendereeByProTypePage`, params);
	},

	// 统计数据查询-按中标人
	getStaBidWinner(params) {
		return http.get(`/mds/interceptApi/bid/getStaBidWinner`, params);
	},
	// 统计数据查询-按中标人-切换
	getStaBidWinnerSwitch(params) {
		return http.get(`/mds/interceptApi/bid/getStaBidWinnerSwitch`, params);
	},
	// 统计数据查询-按中标人-招标单位
	getStaBidWinnerByTenderee(params) {
		return http.get(`/mds/interceptApi/bid/getStaBidWinnerByTenderee`, params);
	},
	// 统计数据查询-按中标人-地区-省
	getStaBidWinnerByAreaProvince(params) {
		return http.get(`/mds/interceptApi/bid/getStaBidWinnerByAreaProvince`, params);
	},
	// 统计数据查询-按中标人-地区-市
	getStaBidWinnerByAreaCity(params) {
		return http.get(`/mds/interceptApi/bid/getStaBidWinnerByAreaCity`, params);
	},
	// 统计数据查询-按中标人-地区-区
	getStaBidWinnerByAreaProjectCounty(params) {
		return http.get(`/mds/interceptApi/bid/getStaBidWinnerByAreaProjectCounty`, params);
	},
	// 统计数据查询-按中标人-项目类型
	getStaBidWinnerByProType(params) {
		return http.get(`/mds/interceptApi/bid/getStaBidWinnerByProType`, params);
	},
	// 统计数据查询-按中标人-招标单位-分页
	getStaBidWinnerByTendereePage(params) {
		return http.get(`/mds/interceptApi/bid/getStaBidWinnerByTendereePage`, params);
	},
	// 统计数据查询-按中标人-地区-分页
	getStaBidWinnerByAreaPage(params) {
		return http.get(`/mds/interceptApi/bid/getStaBidWinnerByAreaPage`, params);
	},
	// 统计数据查询-按中标人-项目类型-分页
	getStaBidWinnerByProTypePage(params) {
		return http.get(`/mds/interceptApi/bid/getStaBidWinnerByProTypePage`, params);
	},
	// 中标大数据定制
	consultingBidDate(params) {
		return http.post(`/mds/interceptApi/demand/consultingBidDate`, params);
	},
	// 统计数据查询-按地区-招标人详情
	getStatisticsByAreaForTenderee(params) {
		return http.post(`/mds/interceptApi/bid/getStatisticsByAreaForTenderee`, params);
	},

	// 统计数据查询-按地区-中标人详情
	getStatisticsByAreaForBidWinner(params) {
		return http.post(`/mds/interceptApi/bid/getStatisticsByAreaForBidWinner`, params);
	},
	// 招标人详情-导出
	exportBidDetailForTender(params) {
		return downloads(`/mds/interceptApi/bid/exportBidDetailForTender`, params, "post");
	},
	// 中标人详情-导出
	exportBidDetailForBidWinner(params) {
		return downloads(`/mds/interceptApi/bid/exportBidDetailForBidWinner`, params, "post");
	},

	// 高级查询获取链接
	getUrl(params) {
		return http.get(`/mds/interceptApi/bid/getUrl`, params);
	},
	// 获取时间
	getPublishedDateRange(params) {
		return http.get(`/mds/api/bid/getPublishedDateRange`, params);
	},
	// 按招标人-地区不分页
	getStaTendereeByAreaList(params) {
		return http.get(`/mds/interceptApi/bid/getStaTendereeByAreaList`, params);
	},
	// 按中标人-地区不分页
	getStaBidWinnerByAreaList(params) {
		return http.get(`/mds/interceptApi/bid/getStaBidWinnerByAreaList`, params);
	},
	// 降低风险等级
	getAntiReptilePass(params) {
		return http.get(`/mds/antiReptile/pass`, params);
	},
	// 支付-订单信息
	getPayOrderDeatil(query, params) {
		return http.post(`/mds/interceptApi/bid/getPayOrderDeatil/${query}`, params);
	},
	// 支付
	payOrder(query, params) {
		return http.post(`/mds/interceptApi/consumerOrder/data/pay`, params);
	},
	// 支付
	getDescriptionList( params) {
		return http.post(`/mds/api/description/list`, params);
	},
	// 获取请求数据转成base64
	getParams(params) {
		return http.post(`/mds/api/download/getParams`, params);
	},
	// 导出统计数据-按地区
	exportStatisticsByAreaByStr(params) {
		return downloads(`/mds/interceptApi/bid/exportStatisticsByAreaByStr`, params, "get");
	},
	// 按招标人表格-导出
	exportStaTendereeByWinnerByStr(params) {
		return downloads(`/mds/interceptApi/bid/exportStaTendereeByWinnerByStr`, params, "get");
	},
	// 按中标人表格-导出
	exportStaBidWinnerByTendereeByStr(params) {
		return downloads(`/mds/interceptApi/bid/exportStaBidWinnerByTendereeByStr`, params, "get");
	},
	//getEconomicDatabaseDownloadByStr
	getEconomicDatabaseDownloadByStr(params) {
		return downloads(`/mds/interceptApi/industry/getEconomicDatabaseDownloadByStr`, params, "get");
	},
	//getEconomicDatabaseDownloadByStr
	getReportPdf(params) {
		return downloads(`/mds/interceptApi/report/getReportPdf`, params, "get");
	},
	
};
