import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./config";
import { Message } from "element-ui";
import store from '@/store';
if (!store.getters.descriptionList||!store.getters.descriptionList.length) {
  store.dispatch('app/getDescriptionList')
} 
Vue.use(VueRouter);
const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});
// 路由拦截
router.beforeEach((to, from, next) => {
  if (!store.getters.descriptionList||!store.getters.descriptionList.length) {
    store.dispatch('app/getDescriptionList')
  } 
  // 获取地区数据
  if (!store.getters.regionList||!store.getters.regionList.length) {
    store.dispatch('app/setRegion')
  } 
  if (to.meta.needLogin) {
    if (window.localStorage.getItem("LOGIN_USERS_KEY")) {
      next();
    } else {
      Message.error("请登录");
      next({ path: "/login" });
    }
  } else {
    next();
  }
});
export default router;
