const sm2 = require('sm-crypto').sm2;
const enPublicKey = '04289b01c4bff8ad6072a9192eb21bf35e7e5853cea577aad007193d9cba01f2bdff68a08580daadac086317a172d32dd99075022f2427e6a73ce37d311dde1d32'
const dePrivateKey = '370c9f89c77450428f8522ab41d9f6f7d4712792365a96a9350a372c69c64106'

/**
 * sm2 加密
 * @param {*} enStr
 */
export function doEncrypt(enStr) {
  const data = typeof enStr !== 'string' ? JSON.stringify(enStr) : enStr;
  const publicKey = enPublicKey // 公钥
  const cipherMode = 1;  // 1 - C1C3C2；	0 - C1C2C3；  默认为1
  const doEncrypt = sm2.doEncrypt(data, publicKey, cipherMode);

  return `04${doEncrypt}`
}

/**
 * sm2 解密
 * @param {*} enStr
 */
export function doDecryptStr(enStr) {
  const data = typeof enStr !== 'string' ? JSON.stringify(enStr) : enStr;
  const sm2 = require('sm-crypto').sm2;
  const cipherMode = 1;
  const privateKey = dePrivateKey // 私钥
  const newData = data.substring(2)
  const doDecrypt = sm2.doDecrypt(newData, privateKey, cipherMode);

  return JSON.parse(doDecrypt)
}
