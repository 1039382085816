import axios from "axios";
import { session } from "./sessions";
/**
 * 导出文件方法
 * @param {String} method [请求方式]
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {String} fileName [导出的文件名，例：test.xlsx]
 */
export const download = (url, fileName, method = "get") => {
  return new Promise(resolve => {
    axios({
      method: method,
      url: (process.env.VUE_APP_BASE_URL||'')+url,
      responseType: "blob"
    }).then(res => {
      let reader = new FileReader();
      let data = res.data;
      reader.onload = e => {
        if (
          e.target.result.indexOf("Result") != -1 &&
          JSON.parse(e.target.result).Result == false
        ) {
          // 进行错误处理
        } else {
          if (!fileName) {
            let contentDisposition = res.headers["content-disposition"];
            if (contentDisposition) {
              fileName = window.decodeURI(
                res.headers["content-disposition"].split("=")[2].split("''")[1],
                "UTF-8"
              );
            }
          }
          executeDownload(data, fileName);
        }
      };
      reader.readAsText(data);
      resolve(res.data);
    });
  });
};

/**
 * 导出文件方法
 * @param {String} method [请求方式]
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export const downloads = (url, params, method = "get") => {
  return new Promise(resolve => {
    axios.defaults.headers.common["Authorization"] = session.getLoginToken();
    let data = {
      method: method,
      url: (process.env.VUE_APP_BASE_URL||'')+url,
      responseType: "blob"
    };
    console.log(data);
    if (method == "get") {
      data["params"] = params;
    } else {
      data["data"] = params;
    }
    axios(data).then(res => {
      resolve(res.data);
    });
  });
};
