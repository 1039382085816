import api from "@/api";

const state = {
	descriptionList: "",
	kkFileUrl: "",
	servicePhone: "",
	regionList: [],
};
const mutations = {
	SET_DESCRIPTIONLIST: (state, value) => {
		state.descriptionList = value;
		value.map(item => {
			if (item.moduleName == "kkFileNew") {
				state.kkFileUrl = item.dataDescription;
			}
			if (item.moduleName == "客服联系") {
				state.servicePhone = item.moduleCode;
			}
		});
	},
	SET_REGION: (state, regionList) => {
		state.regionList = regionList;
	},
};
const actions = {
	getDescriptionList({commit}) {
		api.successful.getDescriptionList({moduleName: ""}).then(r => {
			commit("SET_DESCRIPTIONLIST", r.data);
		});
	},
	setRegion({commit}, user) {
		let obj = {
			parentRegion: 1,
			isLazyLoad: 10011001,
			state: 10021001,
			hasChildren: true,
		};
		api.system.regionList(obj).then(res => {
			res.data.map(item=>{
				item.level=1
				item.name=item.regionFullname
				item.code=item.unionCode
			})
			commit("SET_REGION", res.data);
		});
	},
};
export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
