import http from "../../utils/http";

export default {
	// 获取短信验证码
	getVerificationCode(params) {
		return http.get("/mds/api/sms/getVerificationCode", params);
	},
	// 登录
	doLogin(params) {
		return http.post("/auth/api/login", params);
	},
	// 单点登录
	autoLogin(params) {
		return http.get("/auth/api/thirdPartyFreeLogin", params);
	},
	// 获取登录状态
	getQrStatus(params) {
		return http.get("/auth/api/getQrStatus", params);
	},
	// 支付宝登录
	aliLogin(params) {
		return http.get("/auth/api/aliLogin", params);
	},
	// 支付宝重定向获取用户信息
	authRedirect(params) {
		return http.get("/auth/api/authRedirect", params);
	},
	// 微信登录信息
	wxLogin(params) {
		return http.get("/auth/api/wxLogin", params);
	},
	// 微信获取用户信息
	wxRedirect(params) {
		return http.get("/auth/api/wxRedirect", params);
	},
	// 绑定账号
	bidding(params) {
		return http.post("/auth/api/bidding", params);
	},
};
