<template>
  <div class="uncopy" id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    $route() {
      window.scrollTo(0, 0);
    },
  },
  // data() {
  //   return {
  //     beforeunloadTime: 0,
  //     unloadTime: 0,
  //   };
  // },
  // mounted() {
  //   window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  //   window.addEventListener("unload", (e) => this.unload(e));
  // },
  // destroyed() {
  //   window.removeEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  //   window.removeEventListener("unload", (e) => this.unload(e));
  // },
  // methods: {
  //   beforeunloadHandler() {
  //     this.beforeunloadTime = new Date().getTime();
  //   },
  //   unload() {
  //     this.unloadTime = new Date().getTime();
  //     // 本地通过localStorage中的数据看出,关闭事件间隔小于1,刷新则大于8
  //     if (this.unloadTime - this.beforeunloadTime <= 1) {
  //       localStorage.clear();
  //     }
  //   },
  // },
};
</script>

<style lang="less">
@import "./theme/index.less";
@import "./assets/iconfont/iconfont.css";
@import "./utils/animate.css";
@import "./components/css/index.css";

#app {
  width: 100vw;
  height: 100vh;
}
</style>
