import http from "../../utils/http";

import {downloads} from "../../utils/download";
let url = "/mds/interceptApi";
export default {
	// 用户个人信息
	getInfo(params) {
		return http.get(`${url}/consumer/getInfo`, params);
	},
	// 用户身份变更
	changeConsumerType(params) {
		return http.get(`${url}/home/changeConsumerType`, params);
	},
	// 分页查询用户互动消息
	getNewsInteraction(params) {
		return http.get(`${url}/consumer/getNewsInteraction`, params);
	},
	//分页查询用户订单消息
	getNewsOrder(params) {
		return http.get(`${url}/consumer/getNewsOrder`, params);
	},
	// 分页查询用户系统消息
	getNewsSystem(params) {
		return http.get(`${url}/consumer/getNewsSystem`, params);
	},
	// 用户互动消息回复
	newsInteractionReply(params) {
		return http.get(`${url}/consumer/newsInteractionReply`, params);
	},
	// 订单消息已读
	newsOrderRead(params) {
		return http.get(`${url}/consumer/newsOrderRead`, params);
	},
	// 系统消息已读
	newsSystemRead(params) {
		return http.get(`${url}/consumer/newsSystemRead`, params);
	},
	// 会员续费记录
	buyMemberHistory(params) {
		return http.get(`${url}/consumer/buyMemberHistory`, params);
	},
	// 获取用户会员权限
	getCustmerMember(params) {
		return http.get(`${url}/consumer/getCustmerMember`, params);
	},
	// 分页查询用户收藏
	pageCollection(params) {
		return http.get(`${url}/consumerCollection/page`, params);
	},
	// 收藏
	collection(params) {
		return http.get(`${url}/consumerCollection/collection`, params);
	},
	// 取消收藏
	cancel(params) {
		return http.get(`${url}/consumerCollection/cancel`, params);
	},

	// 定制、咨询订单
	consultPage(params) {
		return http.get(`${url}/consumerOrder/consultPage`, params);
	},
	// 定制、咨询订单支付
	payConsult(params) {
		return http.post(`${url}/consumerOrder/payCode`, params);
	},
	// 会员订单
	memberPage(params) {
		return http.get(`mds/memberOrder/consumer/page`, params);
	},
	// 删除会员订单
	deleteMemberOrder(params) {
		return http.delete(`mds/memberOrder/consumer`, params);
	},
	// 取消会员订单
	closedMemberOrder(id) {
		return http.put(`mds/memberOrder/closed?orderId=${id}`);
	},
	// 会员订单判断
	memberPageJudge(params) {
		return http.get(`${url}/consumerOrder/memberPageJudge`, params);
	},
	// 会员订单支付
	payMember(params) {
		return http.post(`${url}/consumerOrder/payMember`, params);
	},
	// 会员订单支付new
	payMemberVip(params) {
		return http.post(`${url}/consumerOrder/member/pay`, params);
	},
	// 会员开通订单明细页
	payMemberOrderDetail(params) {
		return http.post(`${url}/consumer/payMemberOrderDetail`, params);
	},
	// 研报订单
	reportPage(params) {
		return http.get(`mds/dataReportOrder/consumer/page`, params);
	},
	// 研报订单支付
	payReport(params) {
		return http.post(`${url}/consumerOrder/payReport`, params);
	},
	// 取消研报订单
	closedReportOrder(id) {
		return http.put(`/mds/dataReportOrder/closed?orderId=${id}`);
	},
	// 删除研报订单
	deleteReportOrder(id) {
		return http.delete(`/mds/dataReportOrder/consumer?orderIds=${id}`);
	},
	// 获取开票历史
	getHistoryInfo(params) {
		return http.get(`${url}/consumerFinance/getHistoryInfo`, params);
	},
	// 开票列表
	pageConsumerFinance(params) {
		return http.get(`${url}/consumerFinance/page`, params);
	},
	// 发票下载
	downloadConsumerFinance(params) {
		return http.get(`${url}/consumerFinance/download`, params);
	},
	// 开票
	makeInvoice(params) {
		return http.post(`${url}/consumerFinance/makeInvoice`, params);
	},
	// 下载记录
	downloadHistory(params) {
		return http.get(`${url}/consumerPurchase/downloadHistory`, params);
	},
	// 已购服务列表
	pageConsumerPurchase(params) {
		return http.get(`${url}/consumerPurchase/page`, params);
	},
	// 已购服务下载
	downloadPurchase(params) {
		return downloads(`${url}/consumerPurchase/download`, params);
	},
	// 已购服务研报id获取
	getReportId(params) {
		return http.get(`${url}/consumerPurchase/getReportId`, params);
	},

	// 添加子账户
	addChildAccount(params) {
		return http.post(`${url}/consumerCenter/addChildAccount`, params);
	},
	// 原手机号验证码验证
	judgePhone(params) {
		return http.post(`${url}/consumerCenter/judgePhone`, params);
	},
	// 修改账户信息
	update(params) {
		return http.post(`${url}/consumerCenter/update`, params);
	},
	// 修改手机号
	updatePhone(params) {
		return http.post(`${url}/consumerCenter/updatePhone`, params);
	},
	// 子账户列表
	getChildInfo(params) {
		return http.get(`${url}/consumer/getChildInfo`, params);
	},
	// 子账户信息查询
	childAccountPage(params) {
		return http.get(`${url}/consumerCenter/childAccountPage`, params);
	},
	// 解绑
	unbundling(params) {
		return http.get(`${url}/consumerCenter/unbundling`, params);
	},

	// 会员详情
	getMemberInfo(params) {
		return http.get(`/mds/api/consumer/getMemberInfo`, params);
	},
	// 会员购买详情
	buyMemberInfo(params) {
		return http.get(`${url}/consumer/buyMemberInfo`, params);
	},
	// 会员详情（vip）
	getVipMember(params) {
		return http.get(`/mds/memberInfo/getInfoNoAuth/` + params);
	},
	// 会员特权（vip）
	getPermissionsList(params) {
		return http.get(`/mds/memberInfo/getPermissionsLisTwo`, params);
	},
	// 获取财务对公账户详细信息
	payReceiptInfo(params) {
		return http.get(`${url}/report/payReceiptInfo`, params);
	},
	// 文件上传
	upload(params) {
		return http.formDataPost(`/mds/api/file/upload`, params);
	},
	// 会员购买详情
	buyMember(params) {
		return http.post(`/mds/interceptApi/consumer/buyMember`, params);
	},

	// 个人中心-我的订阅
	// 订阅客户列表
	consumerSubscribe(params) {
		return http.get(`${url}/consumerSubscribe/page`, params);
	},
	// 获取订阅设置
	getSiteSubscribe(params) {
		return http.get(`${url}/consumerSubscribe/getSite`, params);
	},
	// 订阅设置
	siteSubscribe(params) {
		return http.post(`${url}/consumerSubscribe/site`, params);
	},

	// 个人中心-我的客户
	// 我的客户列表
	consumerClient(params) {
		return http.get(`${url}/consumerClient/page`, params);
	},
	// 获取客户信息
	getClientInfo(params) {
		return http.get(`${url}/clientInfo`, params);
	},
	// 新增客户信息
	insertClientInfo(params) {
		return http.post(`${url}/consumerClient/insertClientInfo`, params);
	},
	// 修改客户信息
	updateClientInfo(params) {
		return http.post(`${url}/consumerClient/updateClientInfo`, params);
	},
	// 我的客户-是否为我的客户
	isMyClient(params) {
		return http.get(`${url}/consumerClient/isMyClient`, params);
	},
	// 同步数据
	synchronousData(params) {
		return http.get(`${url}/consumerClient/synchronousData`, params);
	},
	// 获取省市区
	getAddress(params) {
		return http.get(`${url}/consumerClient/getAddress`, params);
	},
	// 项目列表获取  不分页
	getClientProjectNoPage(params) {
		return http.get(`${url}/consumerClient/getClientProjectNoPage`, params);
	},
	// 我的客户-分享获取
	getShareClientInfo(params) {
		return http.formPost(`${url}/consumerClient/getShareClientInfo`, params);
	},
	// 我的客户-分享
	shareClientInfo(params) {
		return http.post(`${url}/consumerClient/shareClientInfo`, params);
	},
	// 我的客户-取消分享
	cancelShareClientInfo(params) {
		return http.formPost(`${url}/consumerClient/cancelShareClientInfo`, params);
	},
	// 我的客户-分享-通过手机号获取用户信息
	getShareConsumerInfo(params) {
		return http.formPost(`${url}/consumerClient/getShareConsumerInfo`, params);
	},
	// 我的客户-文件夹-文件列表
	myClientInfoArchivesFile(params) {
		return http.get(`${url}/consumerClient/getClientInfoArchivesFile`, params);
	},
	// 我的客户-文件夹-文件夹
	myClientInfoArchivesFolder(params) {
		return http.get(`${url}/consumerClient/getClientInfoArchivesFolder`, params);
	},

	// 客户信息详情

	// 客户信息详情-客户资料-上传资料
	addClientArchivesFile(params) {
		return http.post(`${url}/consumerClient/addClientArchivesFile`, params);
	},
	// 客户信息详情-客户资料-新增文件夹
	addClientArchivesFolder(params) {
		return http.post(`${url}/consumerClient/addClientArchivesFolder`, params);
	},
	// 客户信息详情-客户资料-修改文件夹
	updateClientArchivesFolder(params) {
		return http.post(`${url}/consumerClient/updateClientArchivesFolder`, params);
	},
	// 客户信息详情-客户资料库-删除文件夹
	deleteClientArchivesFolder(params) {
		return http.get(`${url}/consumerClient/deleteClientArchivesFolder`, params);
	},
	// 客户信息详情-客户资料库-文件夹-文件列表
	getClientInfoArchivesFile(params) {
		return http.get(`${url}/clientInfo/getClientInfoArchivesFile`, params);
	},
	// 客户信息详情-客户资料库-文件夹-文件夹
	getClientInfoArchivesFolder(params) {
		return http.get(`${url}/clientInfo/getClientInfoArchivesFolder`, params);
	},
	// 客户信息详情-客户资料库-删除客户档案文件
	deleteFile(params) {
		return http.get(`${url}/consumerClient/deleteFile/` + params);
	},
	// 客户信息详情-客户资料库-获取档案文件富文本
	getFileDtl(params) {
		return http.get(`${url}/consumerClient/getFileDtl/` + params);
	},
	// 客户信息详情-客户资料库-根据id获取文件
	getFileInfo(params) {
		return http.get(`/mds/api/file/getInfo`, params);
	},
	// 客户信息详情-客户资料库-存储空间
	getClientSpace(params) {
		return http.get(`${url}/consumerClient/getClientSpace`, params);
	},

	// 客户信息详情-项目跟踪记录-项目列表
	getClientProject(params) {
		return http.get(`${url}/consumerClient/getClientProject`, params);
	},
	// 客户信息详情-项目跟踪记录-新增项目
	addClientProject(params) {
		return http.post(`${url}/consumerClient/addClientProject`, params);
	},
	// 客户信息详情-项目跟踪记录-修改项目
	updateClientProject(params) {
		return http.post(`${url}/consumerClient/updateClientProject`, params);
	},
	// 客户信息详情-项目跟踪记录-删除项目
	deleteClientProject(params) {
		return http.delete(`${url}/consumerClient/deleteClientProject/` + params);
	},
	// 客户信息详情-项目跟踪记录-分类
	getProjectType(params) {
		return http.get(`${url}/consumerClient/getProjectType`, params);
	},

	// 客户信息详情-项目跟踪记录-列表
	getClientProjectRecord(params) {
		return http.get(`${url}/consumerClient/getClientProjectRecord`, params);
	},
	// 客户信息详情-项目跟踪记录-项目详情
	ClientProject(params) {
		return http.formPost(`${url}/consumerClient/getClientProject`, params);
	},
	// 客户信息详情-项目跟踪记录-新增项目跟踪记录
	addClientProjectRecord(params) {
		return http.post(`${url}/consumerClient/addClientProjectRecord`, params);
	},
	// 客户信息详情-项目跟踪记录-修改项目跟踪记录
	updateClientProjectRecord(params) {
		return http.post(`${url}/consumerClient/updateClientProjectRecord`, params);
	},
	// 客户信息详情-项目跟踪记录-删除项目跟踪记录
	deleteClientProjectRedord(params) {
		return http.get(`${url}/consumerClient/deleteClientProjectRedord`, params);
	},

	// 客户信息详情-人员信息-删除人员信息
	deleteClientStaff(params) {
		return http.formPost(`${url}/consumerClient/deleteClientStaff`, params);
	},
	// 客户信息详情-人员信息-人员信息列表
	getClientStaff(params) {
		return http.get(`${url}/consumerClient/getClientStaff`, params);
	},
	// 客户信息详情-人员信息-新增人员信息
	insertClientStaff(params) {
		return http.post(`${url}/consumerClient/insertClientStaff`, params);
	},
	// 客户信息详情-人员信息-修改人员信息
	updateClientStaff(params) {
		return http.post(`${url}/consumerClient/updateClientStaff`, params);
	},
	// 数据收费订单分页
	getDataPriceOrder(params) {
		return http.get(`/mds/dataPriceOrder/consumer/page`, params);
	},
	// 取消数据收费订单
	closedDataOrder(id) {
		return http.put(`/mds/dataPriceOrder/closed?orderId=${id}`);
	},
	// 预览数据
	getDataPreview(params) {
		return http.get(`/mds/dataPriceOrder/preview`, params);
	},

	//行业动态订单
	//订单分页
	industryOrderPage(params) {
		return http.get(`/mds/industryOrder/consumer/page`, params);
	},
	industryOrderClosedPay(params) {
		return http.put(`/mds/industryOrder/closed?orderId=` + params);
	},
	deleteIndustryOrder(id) {
		return http.delete(`/mds/industryOrder/consumer?orderIds=${id}`);
	},
	getIndustryOrder(params) {
		return http.get(`/mds/industryOrder/get`, params);
	},
	// 获取下载按钮是否显示
	getDownloadButtonShow(params) {
		return http.get(`/mds/api/member/getDownloadButtonShow`, params);
	},
	// 获取未支付订单数
	getNoOrderCount(params) {
		return http.get(`/mds/interceptApi/consumerOrder/getOrderCount`, params);
	},

};
