export default {
	/**
	 * 图片地址拼接
	 * @param {*} img 图片地址
	 * @returns
	 */
	picturePrefix(img = "") {
		if (!img) {
			return "-";
		}
		// 前缀+传入的图片地址
		return `${process.env.VUE_APP_IMG_URL}${img}`;
	},

	/**
	 * 姓名格式化 只显示第一位，后面的*代替
	 * @param {*} name 姓名
	 * @returns
	 */
	formattedName(name = "") {
		name = name || "";
		name = name.toString();
		return name.substring(0, 1) + new Array(name.length).join("*");
	},

	/**
	 * 手机号码格式化中间4位显示*
	 * @param {*} phone 手机号码
	 * @returns
	 */
	formattedPhone(phone = "") {
		phone = phone || "";
		phone = phone.toString();
		return phone.replace(phone.substr(3, 4), "****");
	},
	/**
	 * 价格格式化
	 * @param {*} number 价格
	 * @param {*} places 保留小数位数
	 * @param {*} thousand 千位分隔符
	 * @param {*} decimal 小数分隔符
	 * @returns
	 */
	formatMoney(number = 0, places = 2, thousand = ",", decimal = ".") {
		thousand = thousand || ",";
		decimal = decimal || ".";
		var negative = number < 0 ? "-" : "",
			i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + "",
			j = (j = i.length) > 3 ? j % 3 : 0;
		return (
			negative +
			(j ? i.substr(0, j) + thousand : "") +
			i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) +
			(places
				? decimal +
				  Math.abs(number - i)
						.toFixed(places)
						.slice(2)
				: "")
		);
	},
	/**
	 * 多维数组转二位数组
	 * @param {*} params
	 */
	moreOrTwoArr(params) {
		let newTotalArr = [];
		let self = this;

		function moreArr(oldArr, newArr) {
			oldArr.map(item => {
				newArr.push({
					accessoriesName: item.accessoriesName,
					accessoriesNum: item.accessoriesNum,
					customerId: item.customerId,
					customerName: item.customerName,
					createDate: item.createDate,
					info: item.info,
					orderId: item.orderId,
					pid: item.pId,
					photo: self.picturePrefix(item.photo),
					type: item.type,
					id: item.id,
				});
				if (item.orderCommentsList) {
					moreArr(item.orderCommentsList, newArr);
				}
			});
		}

		params.map(item => {
			let json = {
				accessoriesName: item.accessoriesName,
				accessoriesNum: item.accessoriesNum,
				customerId: item.customerId,
				customerName: item.customerName,
				createDate: item.createDate,
				info: item.info,
				photo: this.picturePrefix(item.photo),
				orderId: item.orderId,
				pid: item.pId,
				type: item.type,
				id: item.id,
			};

			if (item.orderCommentsList) {
				let newArr = [];
				moreArr(item.orderCommentsList, newArr);
				json.orderCommentsList = newArr;
			}
			newTotalArr.push(json);
		});

		return newTotalArr;
	},

	/**
	 * 时间格式化
	 * @param {*} date 时间或者时间字符串
	 * @param {*} format 时间格式
	 * @returns 格式化后的时间字符串
	 */
	dateFormat(date, format = "yyyy-mm-dd") {
		let dt = date;
		if (!(date instanceof Date)) {
			if (typeof date == "string") {
				dt = new Date(Date.parse(date.replace(/-/g, "/")));
			} else if (date instanceof Number) {
				dt = new Date(date);
			}
		}
		if (dt == "Invalid Date") {
			return "-";
		}
		let y = dt.getFullYear();
		let m = (dt.getMonth() + 1).toString().padStart(2, "0");
		let d = dt
			.getDate()
			.toString()
			.padStart(2, "0");
		let h = dt
			.getHours()
			.toString()
			.padStart(2, "0");
		let mm = dt
			.getMinutes()
			.toString()
			.padStart(2, "0");
		// let s = dt
		//   .getSeconds()
		//   .toString()
		//   .padStart(2, "0");
		if (format.toLocaleLowerCase() === "yyyy-mm-dd" || format.toLocaleLowerCase() === "") {
			return `${y}-${m}-${d}`;
		} else if (format.toLocaleLowerCase() === "hh:mm") {
			return `${h}:${mm}`;
		} else if (format.toLocaleLowerCase() === "mm-dd") {
			return `${m}-${d}`;
		} else if (format.toLocaleLowerCase() === "dd") {
			return `${d}`;
		} else if (format.toLocaleLowerCase() === "yyyy-mm") {
			return `${y}-${m}`;
		} else if (format.toLocaleLowerCase() === "yyyy年mm月") {
			return `${y}年${m}月`;
		} else if (format.toLocaleLowerCase() === "mm月dd日 hh:mm") {
			return `${m}月${d}日 ${h}:${mm}`;
		} else {
			return `输入的时间格式有误`;
		}
	},
	/**
	 * 大数字转换，将大额数字转换为万、千万、亿等
	 * @param value 数字值
	 */
	formatUnit(value) {
		const newValue = ["", ""];
		let fr = 1000;
		let num = 3;
		let text1 = "";
		let fm = 1;
		while (value / fr >= 1) {
			fr *= 10;
			num += 1;
			// console.log('数字', value / fr, 'num:', num)
		}
		if (num <= 4) {
			// 千
			newValue[0] = parseInt(value / 1000) + "";
			newValue[1] = "千元";
		} else if (num <= 8) {
			// 万
			text1 = parseInt(num - 4) / 3 > 1 ? "千万元" : "万元";
			// tslint:disable-next-line:no-shadowed-variable
			fm = text1 === "万元" ? 10000 : 10000000;
			if (value % fm === 0) {
				newValue[0] = parseInt(value / fm) + "";
			} else {
				newValue[0] = parseFloat(value / fm).toFixed(2) + "";
			}
			newValue[1] = text1;
		} else if (num <= 16) {
			// 亿
			text1 = (num - 8) / 3 > 1 ? "千亿元" : "亿元";
			text1 = (num - 8) / 4 > 1 ? "万亿元" : text1;
			text1 = (num - 8) / 7 > 1 ? "千万亿元" : text1;
			// tslint:disable-next-line:no-shadowed-variable
			fm = 1;
			if (text1 === "亿元") {
				fm = 100000000;
			} else if (text1 === "千亿元") {
				fm = 100000000000;
			} else if (text1 === "万亿元") {
				fm = 1000000000000;
			} else if (text1 === "千万亿元") {
				fm = 1000000000000000;
			}
			if (value % fm === 0) {
				newValue[0] = parseInt(value / fm) + "";
			} else {
				newValue[0] = parseFloat(value / fm).toFixed(2) + "";
			}
			newValue[1] = text1;
		}
		if (value < 1000) {
			newValue[0] = value + "";
			newValue[1] = "元";
		}
		return newValue;
	},
	/**
	 * 单位转成万
	 *  @param value 数字值
	 */
	formatMyriadr(value) {
		value = Number(value);
		if (value < 10000) {
			return value + "";
		} else {
			return (value / 10000).toFixed(2);
		}
	},
	/**
	 * 单位转成亿
	 *  @param value 数字值
	 */
	toBillion(value) {
		if (value < 100000000) {
			return value / 100000000;
		} else {
			return Math.floor((value / 100000000) * 100) / 100; //保留小数点两位
		}
	},
	/**
	 * 将返回的流数据转换为url
	 *  @param file 文件数据
	 */
	getObjectURL(file) {
		let url = null;
		if (window.createObjectURL != undefined) {
			// basic
			url = window.URL.createObjectURL(new Blob([file], {type: "application/pdf;charset-UTF-8"}));
		} else if (window.webkitURL != undefined) {
			// webkit or chrome
			try {
				url = window.webkitURL.createObjectURL(new Blob([file], {type: "application/pdf;charset-UTF-8"}));
			} catch (error) {}
		} else if (window.URL != undefined) {
			// mozilla(firefox)
			try {
				url = window.URL.createObjectURL(new Blob([file], {type: "application/pdf;charset-UTF-8"}));
			} catch (error) {}
		}
		return url;
	},
	/**
	 * 下载文件
	 * @param {*} data 文件流
	 * @param {*} fileName 下载文件的名称
	 * @returns
	 */
	downloadFile(data, fileName) {
		if (!data) {
			return;
		}
		const u = window.URL || window.webkitURL || window.moxURL;
		// 把文件流转换成url
		let url = u.createObjectURL(new Blob([data], {type: "application/pdf;charset-UTF-8"}));
		// 创建a标签
		let link = document.createElement("a");
		link.style.display = "none";
		link.href = url;
		// 设置文件名称
		link.setAttribute("download", fileName);
		// 把a标签添加到body上
		document.body.appendChild(link);
		// 触发点击事件-用来下载
		link.click();
		// 下载后删除a标签
		document.body.removeChild(link);
		// 释放blob对象
		u.revokeObjectURL(url);
	},
	downloadIamge(imgsrc, name) {
		//下载图片地址和图片名
		let image = new Image();
		// 解决跨域 Canvas 污染问题
		image.setAttribute("crossOrigin", "anonymous");
		image.onload = function() {
			let canvas = document.createElement("canvas");
			canvas.width = image.width;
			canvas.height = image.height;
			let context = canvas.getContext("2d");
			context.drawImage(image, 0, 0, image.width, image.height);
			let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
			let a = document.createElement("a"); // 生成一个a元素
			let event = new MouseEvent("click"); // 创建一个单击事件
			a.download = name || "photo"; // 设置图片名称
			a.href = url; // 将生成的URL设置为a.href属性
			a.dispatchEvent(event); // 触发a的单击事件
		};
		image.src = imgsrc;
	},
	/**
	 * 获取当前月份的天数
	 * @param {*} yuanmonth 传入的月份
	 * @returns
	 */
	getDaysInMonth(yuanmonth) {
		let date = new Date(yuanmonth);
		let month = date.getMonth();
		let days = new Date(date.getFullYear(), month + 1, 0).getDate();
		return days;
	},
	async getDict(dictType) {
		let res = await this.$api.system.getDict(dictType);
		if (res.code == 200) {
			return res.data;
		}
	},
	timeAgo(o) {
		var n = new Date().getTime();
		var f = n - new Date(o).getTime();
		var bs = f >= 0 ? "前" : "后"; //判断时间点是在当前时间的 之前 还是 之后
		f = Math.abs(f);
		if (f < 6e4) {
			return "刚刚";
		} //小于60秒,刚刚
		if (f < 36e5) {
			return parseInt(f / 6e4) + "分钟" + bs;
		} //小于1小时,按分钟
		if (f < 864e5) {
			return parseInt(f / 36e5) + "小时" + bs;
		} //小于1天按小时
		return o.slice(0, 10); //大于1天,返回年月日
	},
	fileLinkToStreamDownload(url, fileName, type) {
		const that = this;
		let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
		if (!reg.test(url)) {
			throw new Error("传入参数不合法,不是标准的文件链接");
		} else {
			let xhr = new XMLHttpRequest();
			xhr.open("get", url, true);
			xhr.setRequestHeader("Content-Type", `application/${type}`);
			xhr.responseType = "blob";
			xhr.onload = function() {
				if (this.status == 200) {
					//接受二进制文件流
					var blob = this.response;
					that.downloadExportFile(blob, fileName, type);
				}
			};
			xhr.send();
		}
	},

	downloadExportFile(blob, fileName, fileType) {
		let downloadElement = document.createElement("a");
		let href = blob;
		if (typeof blob == "string") {
			downloadElement.target = "_blank";
		} else {
			href = window.URL.createObjectURL(blob); //创建下载的链接
		}
		downloadElement.href = href;
		downloadElement.download = fileName;
		document.body.appendChild(downloadElement);
		downloadElement.click(); //点击下载
		document.body.removeChild(downloadElement); //下载完成移除元素
		if (typeof blob != "string") {
			window.URL.revokeObjectURL(href); //释放掉blob对象
		}
	},
};
