import http from "../../utils/http";
export default {
	// 新增会员共享
	save(params) {
		return http.post(`/mds/interceptApi/share/save`, params);
	},
	// 分页查询会员共享列表
	page(params) {
		return http.get(`/mds/interceptApi/share/page`, params);
	},
	//查询会员共享
	getShareInfo(params) {
		return http.get(`/mds/interceptApi/share/getShareInfo`, params);
	},
	//阅读数累加
	realReadingPlusOne(params) {
		return http.get(`/mds/interceptApi/share/realReadingPlusOne`, params);
	},
	// 分页查询会员共享关注列表
	followPage(params) {
		return http.get(`/mds/interceptApi/share/follow/page`, params);
	},
	// 分页查询会员共享关注列表
	shareDownloadFile(params) {
		return http.get(`/mds/interceptApi/share/downloadFile`, params);
	},
	// 关注/取消关注
	followOrCancel(params) {
		return http.post(`/mds/interceptApi/share/followOrCancel`, params);
	},
	// 获取关注用户详情
	getFollowShareInfo(params) {
		return http.get(`/mds/interceptApi/share/follow/getShareInfo`, params);
	},
	// 获取收藏列表
	getCollectionList(params) {
		return http.get(`/mds/interceptApi/share/getCollectionList`, params);
	},
	// 获取下载列表
	getDownloadList(params) {
		return http.get(`/mds/interceptApi/share/getDownloadList`, params);
	},
};
