export const UrlUtil = {
  /**
   * 取指定url上的参数
   * @param url 请求地址
   * @param key 某请求参数key
   * @returns {{}|*}
   */
  getParam(url, key) {
    if (key === undefined) {
      key = url;
      url = window.location.href;
    }
    const search = url.substring(url.lastIndexOf('?') + 1);
    const obj = {};
    const reg = /([^?&=]+)=([^?&=]*)/g;
    search.replace(reg, (rs, $1, $2) => {
      const name = decodeURIComponent($1);
      let val = decodeURIComponent($2);
      val = String(val);
      obj[name] = val;
      return rs;
    });

    if (key && obj) {
      return obj[key];
    }

    return obj;
  },
  /**
   * 获取服务器地址
   * @param void
   */
  getServerUrl() {
    let serverUrl = '';
    let href = window.location.href;
    if (href) {
      serverUrl = href.substr(0, href.indexOf('#') + 2);
    }
    return serverUrl;
  },

  /**
   * @param {Array} actual
   * @returns {Array}
   */
  cleanArray(actual) {
    const newArray = []
    for (let i = 0; i < actual.length; i++) {
      if (actual[i]) {
        newArray.push(actual[i])
      }
    }
    return newArray
  },
  /**
   * @param {Object} json
   * @returns {Array}
   */
  param(json) {
    if (!json) return ''
    return this.cleanArray(
      Object.keys(json).map(key => {
        if (json[key] === undefined) return ''
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
      })
    ).join('&')
  },
  /**
   * 参数处理
   * @param {*} params  参数
   */
  tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
      const value = params[propName];
      let part = encodeURIComponent(propName) + "=";
      if (value !== null && value !== "" && typeof (value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
              let params = propName + '[' + key + ']';
              let subPart = encodeURIComponent(params) + "=";
              result += subPart + encodeURIComponent(value[key]) + "&";
            }
          }
        } else {
          result += part + encodeURIComponent(value) + "&";
        }
      }
    }
    return result
  }
};
