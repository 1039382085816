import VueClipboard from 'vue-clipboard2'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import element from "./utils/element";
import locale from "element-ui/lib/locale/lang/zh-CN";
locale.el.datepicker.months = {
  jan: "1月",
  feb: "2月",
  mar: "3月",
  apr: "4月",
  may: "5月",
  jun: "6月",
  jul: "7月",
  aug: "8月",
  sep: "9月",
  oct: "10月",
  nov: "11月",
  dec: "12月"
};
import "element-ui/lib/theme-chalk/index.css";
import utils from "./utils/utils";
import { session } from "./utils/sessions";
import echarts from "./utils/echarts";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import "./assets/font/index.less";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import modules from "./api";
Vue.prototype.$api = modules;
Vue.prototype.$utils = utils;
Vue.prototype.$session = session;
Vue.prototype.$getDict = utils.getDict;

import tableMove from "./utils/tableMove";
Vue.use(tableMove);
import tools from "./utils/tools";
Vue.use(tools);

Vue.config.productionTip = false;
Vue.use(element);
Vue.use(element, { locale });
Vue.use(echarts);
Vue.use(VueAwesomeSwiper);
Vue.use(VueClipboard)
Vue.prototype.$VERSION = VERSION

Vue.directive('scrollLoad', {
  bind(el, binding, vnode) {
    function handleScroll(e) {
      let isBottom = e.target.clientHeight + e.target.scrollTop >= e.target.scrollHeight - 40
      if (isBottom && !vnode.context.loading) {
        binding.value()
      }
    }
    // 监听滚动
    let wrapDom = el.querySelector('.el-autocomplete-suggestion__wrap')
    el.__handleScroll__ = handleScroll
    el.__wrapDom__ = wrapDom
    wrapDom.addEventListener('scroll', handleScroll, false)
  },

  unbind(el, binding, vnode) {
    // 解除事件监听
    el.__wrapDom__.removeEventListener('scroll', el.__handleScroll__, false)
  },
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
